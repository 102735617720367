const Header  = () => {
    return (
        <header className="App-header">
            <div className="navbar-fixed">
                <nav className="primary">
                    <div className="nav-wrapper">
                        <div className="brand-logo center">Pomogoal</div>
                    </div>
                </nav>
            </div>
        </header>
    );
}

export default Header;