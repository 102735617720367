import React, {useState} from 'react';
import SettingsModalButton from "./Body/SettingsModalButton";
import GoalDisplay from './Body/GoalDisplay';
import GoalsModalCard from "./Body/GoalsModalCard.jsx";
import './Body.css';
import GoalModal from "../modals/GoalModal";
import SettingsModal from "../modals/SettingsModal";
import {getCookie, setCookie} from "../helpers/data/cookies";

const Body = () => {
    if (getCookie('description') === null) {
        setCookie('description', JSON.stringify({title: "Go for Goal", tagline: "With pomogoal.com"}));
    }

    const [goalDescription, setGoalDescription] = useState({
        title: JSON.parse(getCookie('description')).title,
        tagline: JSON.parse(getCookie('description')).tagline,
    });

    const updateGoalDescription = (newGoalDescription) => {
        setGoalDescription(newGoalDescription);
        setCookie('description', JSON.stringify(newGoalDescription));
    };

    return <div className="row">
        <div className="col s12 mt-2">
            <section className="section primary-container on-primary-text m-2" id="index-banner">
                <div className="col s12 m8 offset-m1 xl7 offset-xl1 p-2">
                    <SettingsModalButton/>
                    <GoalDisplay goalDescription={goalDescription} />
                </div>
            </section>
        </div>
        <GoalsModalCard/>
        <GoalModal onClick={updateGoalDescription} goalDescription={goalDescription}/>
        <SettingsModal/>
    </div>
}

export default Body;