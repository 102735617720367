import React from "react";

const SettingsModalButton = () => {
    return (
        <div className="right-align">
            <a className="btn-small text-btn waves-effect waves-light modal-trigger" href="#settings-modal">
                <i className="material-icons">settings</i>
            </a>
        </div>
    );
}

export default SettingsModalButton;