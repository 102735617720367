const Footer  = () => {

    const date = () => {
      let d = new Date();
      return d.getFullYear();
    };

    return (
        <div className="App-footer">
            <p>&copy; {date()} pomogoal.com</p>
        </div>
    );
}

export default Footer;