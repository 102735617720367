const GoalsModalCard  = () => {
    return (
        <div className="col s12">
            <div className="card m-2 blue lighten-4">
                <div className="card-content">
                    <div className="m-1">
                        <a className="btn-small text-btn icon-left waves-effect waves-light modal-trigger"
                           href="#goals-modal">
                            <i className="material-icons">edit</i>
                            Change My Goal
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GoalsModalCard;