import React, {forwardRef, useCallback, useEffect, useState} from "react";
import {useTimer} from "react-timer-hook";
import {getCookie, setCookie} from "../../helpers/data/cookies";

const GoalDisplay = forwardRef ((props, ref) => {
    const [timers] = useState(getCookie('timers') === null
        ? JSON.parse(setCookie('timers', JSON.stringify([
            {id: 0, text: "pomodoro", name:'Pomodoro', duration: 1500},
            {id: 1, text: "shortbreak", name:'Short Break', duration: 300},
            {id: 2, text: "longbreak", name:'Long Break', duration: 900}
          ])))
        : JSON.parse(getCookie('timers')));

    const [duration, setDuration] = useState(timers[0].duration);
    const [name, setName] = useState(timers[0].name);

    const expiryTimestamp = () => {
        const time = new Date();
        return time.setSeconds(time.getSeconds() + duration);
    };

    const {
        seconds,
        minutes,
        pause,
        resume,
        restart,
        isRunning
    } = useTimer({
        expiryTimestamp,
        autoStart: false,
        onExpire() {
            reset();
            const img = "/logo-128.png";
            const text = `HEY! It's time to get going with your next Goal!`;
            new Notification("Pomogoal", { body: text, icon: img });
        }
    });

    const reset = useCallback((duration = timers[0].duration, name = timers[0].name) => {
        setDuration(duration);
        setName(name);
        const time = new Date();
        time.setSeconds(time.getSeconds() + duration);
        restart(time, false);
    }, [restart, timers]);

    useEffect(() => {
        reset();
    }, [props.goalDescription.title, props.goalDescription.tagline, reset]);

    useEffect(() => {
       if (isRunning) {
           document.title = (minutes >= 10 ? minutes : '0' + minutes) + ':' + (seconds >= 10 ? seconds : '0' + seconds) + ' | Pomogoal.com';
       }
       else {
           document.title = 'Pomogoal.com | Going for Goal!';
       }

    });

    return (
        <div>
            <h1 className="header grey-text text-darken-3 text-timer m-5 font-bold"
                id="goal-timer">{minutes >= 10 ? minutes : '0' + minutes}:{seconds >= 10 ? seconds : '0' + seconds}</h1>
            <h5 className="grey-text text-darken-3 text-title m-5 font-size-medium font-bold"
                id="goal-name">{props.goalDescription.title}</h5>
            <h6 className="grey-text text-darken-3 text-tagline font-size-small mb-4"
                id="goal-tagline">{props.goalDescription.tagline}</h6>
            <div className="chip m-2" id="goal-type">
                <i className="material-icons">alarm</i>
                {name}
            </div>
            <div className="m-5">
                <a className="btn-small text-btn waves-effect waves-light m-1" href="#!" onClick={resume}>
                    <i className="material-icons">play_arrow</i>
                </a>
                <a className="btn-small text-btn waves-effect waves-light m-1" href="#!" onClick={pause}>
                    <i className="material-icons">pause</i>
                </a>
                <a className="btn-small text-btn waves-effect waves-light m-1" href="#!" onClick={() => {
                    reset(duration, name)
                }}>
                    <i className="material-icons">refresh</i>
                </a>
            </div>
            <div>
                {
                    timers.map(timer => (
                        <button className="text-btn btn-small waves-effect waves-light m-1"
                                key={timer.id}
                                onClick={() => reset(timer.duration, timer.name)}>
                            {timer.name}
                        </button>
                    ))
                }
            </div>
        </div>
    );
});

export default GoalDisplay;