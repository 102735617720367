import './App.css';
import Header from './components/partials/Header';
import Body from "./components/partials/Body";
import Footer from "./components/partials/Footer";
import "@materializecss/materialize/sass/materialize.scss";
import {M} from "@materializecss/materialize";
import React, {useEffect} from "react";

function App() {
    useEffect(() => {
        M.Modal.init(document.querySelectorAll('.modal'), {});
    }, []);

    Notification.requestPermission().then((permission) => {});



    return (
        <div className="App">
            <Header/>
            <Body/>
            <Footer/>
        </div>
    );
}

export default App;
