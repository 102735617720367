import React, {useRef, useState} from 'react';
import {getCookie} from "../helpers/data/cookies";
import {useFocus} from "../helpers/hooks/focus";

const GoalModal  = ({ onClick }) => {
    const [inputTitleRef, setInputTitleFocus] = useFocus();
    const [inputTaglineRef, setInputTaglineFocus] = useFocus();

    const [inputTitleValue, setInputTitleValue] = useState(JSON.parse(
        getCookie('description')) !== null
            ? JSON.parse(getCookie('description')).title
            : ''
    );

    const [inputTaglineValue, setInputTaglineValue] = useState(JSON.parse(
        getCookie('description')) !== null
            ? JSON.parse(getCookie('description')).tagline
            : ''
    );

    const handleTitleChange = (e) => {
        setInputTitleValue(e.target.value);
    };
    const handleTitleClear = (e) => {
        setInputTitleValue('');
        setInputTitleFocus();
    };

    const handleTaglineChange = (e) => {
        setInputTaglineValue(e.target.value);
    };

    const handleTaglineClear = (e) => {
        setInputTaglineValue('');
        setInputTaglineFocus();
    }

    const handleClick = () => {
        onClick({ title: inputTitleValue, tagline: inputTaglineValue });
    };

    const handleCancel = () => {
        setInputTitleValue(JSON.parse(getCookie('description')).title);
        setInputTaglineValue(JSON.parse(getCookie('description')).tagline);
    }

    return (
        <div id="goals-modal" className="modal">
                <div className="modal-content">
                    <form className="row">
                        <div className="s12">
                            <h5 className="m-4 mb-2 font-bold">New Goal</h5>
                        </div>
                        <div className="s12 input-field">
                            <input id="goal_name" type="text" placeholder=" " maxLength="60" onChange={handleTitleChange} value={inputTitleValue} ref={inputTitleRef}/>
                            <label htmlFor="goal_name">Name</label>
                            <a className="right" onClick={handleTitleClear} href="#!"><i className="material-icons">cancel</i></a>
                        </div>

                        <div className="s12 input-field mt-4">
                            <input id="goal_tagline" type="text" placeholder=" " onChange={handleTaglineChange} value={inputTaglineValue} ref={inputTaglineRef} maxLength="60"/>
                            <label htmlFor="goal_tagline">Tagline</label>
                            <a className="right" onClick={handleTaglineClear} href="#!"><i className="material-icons">cancel</i></a>
                        </div>
                    </form>
                </div>
                <div className="modal-footer">
                    <a href="#!" className="modal-close waves-effect btn-flat"type="submit"
                       name="action" onClick={handleClick} href="#!">Save</a>
                    <a href="#!" className="modal-close waves-effect btn-flat" onClick={handleCancel}>Cancel</a>
                </div>
        </div>
    );
}

export default GoalModal;