import {useState} from "react";
import {eraseCookie, getCookie, setCookie} from "../helpers/data/cookies";

const SettingsModal  = () => {
    const [timers] = useState(getCookie('timers') === null
        ? JSON.parse(setCookie('timers', JSON.stringify([
            {id: 0, text: "pomodoro", name:'Pomodoro', duration: 1500},
            {id: 1, text: "shortbreak", name:'Short Break', duration: 300},
            {id: 2, text: "longbreak", name:'Long Break', duration: 900}
        ])))
        : JSON.parse(getCookie('timers')));

    const getMinutes = (seconds) => {
        return seconds / 60;
    }

    const getSeconds = (minutes) => {
        return minutes * 60;
    }

    const [pomodoro, setPomodoro] = useState(getMinutes(timers[0].duration));
    const [shortBreak, setShortBreak] = useState(getMinutes(timers[1].duration));
    const [longBreak, setLongBreak] = useState(getMinutes(timers[2].duration));

    const handlePomodoroChange = (e) => {
        setPomodoro(e.target.value);
    }

    const handleShortBreakChange = (e) => {
        setShortBreak(e.target.value);
    }

    const handleLongBreakChange = (e) => {
        setLongBreak(e.target.value);
    }

    const updateCookies = () => {
        eraseCookie('timers');
        setCookie('timers', JSON.stringify([
            {id: 0, text: "pomodoro", name:'Pomodoro', duration: parseInt(getSeconds(pomodoro))},
            {id: 1, text: "shortbreak", name:'Short Break', duration: parseInt(getSeconds(shortBreak))},
            {id: 2, text: "longbreak", name:'Long Break', duration: parseInt(getSeconds(longBreak))}
        ]));
    }

    return (
        <div>
            {/* Modal Trigger */}
            <div id="settings-modal" className="modal">
                <div className="modal-content">
                    <h5 className="m-4 mb-4 font-bold">Settings</h5>

                    <div className="row">
                        <div className="s12 input-field m-2">
                            <input id="pomodoro_minutes" type="number" placeholder="MM" value={pomodoro} onChange={handlePomodoroChange}/>
                            <label htmlFor="pomodoro_minutes">Pomodoro (Minutes)</label>
                        </div>
                        <div className="s12 input-field m-2">
                            <input id="short_break_minutes" type="number" placeholder="MM" value={shortBreak} onChange={handleShortBreakChange}/>
                            <label htmlFor="short_break_minutes">Short Break (Minutes)</label>
                        </div>
                        <div className="s12 input-field m-2">
                            <input id="long_break_minutes" type="number" placeholder="MM" value={longBreak} onChange={handleLongBreakChange}/>
                            <label htmlFor="long_break_minutes">Long Break (Minutes)</label>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <a href="#!" className="modal-close waves-effect btn-flat" onClick={updateCookies}>Save</a>
                    <a href="#!" className="modal-close waves-effect btn-flat">Cancel</a>
                </div>
            </div>
        </div>
    );
}

export default SettingsModal;